import React, { useState } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import SignInButton from "../components/SignInButton";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";

const LoginForm = ({ onLogin }) => {
  const [email, setEmail] = useState(""); 
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [token, setToken] = useState(null); // State for JWT token
  const [user, setUser] = useState(null); // State for user details
  const [loginError, setLoginError] = useState(""); // New state for login error message
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
  
    const validateEmail = (email) => {
      if (!email) return "Email cannot be empty";
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email) ? "" : "Invalid email address";
    };
  
    const validatePassword = (password) => {
      if (!password) return "Password cannot be empty";
      return password.length < 8
        ? "Password must be at least 8 characters long"
        : "";
    };
  
    const handleChange = (event) => {
      const { name, value } = event.target;
      if (name === "email") {
        setEmail(value);
      } else if (name === "password") {
        setPassword(value);
      }
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsSubmitted(true);
      setLoginError(""); // Clear previous login error
      const emailError = validateEmail(email);
      const passwordError = validatePassword(password);
  
      if (!emailError && !passwordError) {
        setIsLoading(true);
        try {
          const response = await axios.post(`${apiUrl}/login`, {
            email,
            password,
          });
          
          // Handle response based on the backend logic
          if (response.status === 200) {
            const { token, userDetails } = response.data;
            if (token) {
              setToken(token); // Store the token
              setUser(userDetails); // Store the user details
              localStorage.setItem("token", token);
              if (onLogin) {
                onLogin(email, password);
              }
              navigate("/home");
            } else {
              // Handle case where token is not present but response status is 200
              setLoginError("Login unsuccessful. Please verify your email and password.");
            }
          } else if (response.status === 401) {
            setLoginError("User not verified. Please check your email for verification instructions.");
          } else if (response.status === 404) {
            setLoginError("User not found. Please check your email and try again.");
          } else {
            // Handle unexpected responses
            setLoginError("Login unsuccessful. Please try again later.");
          }
        } catch (error) {
          // Handle network errors and other unexpected issues
          if (error.response) {
            // Extract the message from the response and display it
            setLoginError(error.response.data.message || "An error occurred. Please try again.");
          } else {
            setLoginError("An error occurred. Please try again.");
          }
          // console.error("There was an error logging in!", error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setErrors({ email: emailError, password: passwordError });
      }
    };
  
    return (
      <div className="login-container">
        {isLoading && (
          <div className="loader">
            <TailSpin
              visible={true}
              height="80"
              width="80"
              color="#9cbaa4"
              ariaLabel="tail-spin-loading"
              radius="1"
            />
          </div>
        )}
        <form onSubmit={handleSubmit} className="login-form">
          <TextField
            name="email"
            label="Email"
            variant="outlined"
            type="email"
            placeholder="E-mail"
            fullWidth
            error={isSubmitted && !!errors.email}
            sx={{
              borderRadius: "50px",
              background: "rgba(255, 255, 255, 0.08)",
              marginBottom: "1rem",
              height: "56px",
              "& label": { color: "white" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { border: "none" },
                "&:hover fieldset": { border: "none" },
                "&.Mui-focused fieldset": { border: "none" },
                "& .MuiInputBase-input": {
                  color: "white",
                  height: "1.5em",
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon sx={{ color: "#E8364E" }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {isSubmitted && errors.email && (
                    <span style={{ color: "red", fontSize: "0.75rem" }}>
                      {errors.email}
                    </span>
                  )}
                </InputAdornment>
              ),
            }}
            onChange={handleChange}
            value={email}
          />
  
          <TextField
            name="password"
            label="Password"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            fullWidth
            error={isSubmitted && !!errors.password}
            sx={{
              borderRadius: "50px",
              background: "rgba(255, 255, 255, 0.08)",
              height: "56px",
              "& label": { color: "white" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { border: "none" },
                "&:hover fieldset": { border: "none" },
                "&.Mui-focused fieldset": { border: "none" },
                "& .MuiInputBase-input": {
                  color: "white",
                  height: "1.5em",
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon sx={{ color: "#E8364E" }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {isSubmitted && errors.password && (
                    <span style={{ color: "red", fontSize: "0.75rem" }}>
                      {errors.password}
                    </span>
                  )}
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePasswordVisibility}
                    edge="end"
                    sx={{ color: "white" }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={handleChange}
            value={password}
          />
  
          <Typography
            sx={{
              color: "#FFF",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              textDecorationLine: "underline",
              marginBottom: "1rem",
              marginTop: "1rem",
            }}
          >
            By signing up, you accept privacy policy & terms of services
          </Typography>
  
          {/* Display login error message */}
          {loginError && (
            <Typography
              sx={{
                color: "red",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                marginBottom: "1rem",
              }}
            >
              {loginError}
            </Typography>
          )}
  
          <SignInButton buttonText="Sign In" />
        </form>
      </div>
    );
  };
  
  export default LoginForm;
  