import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import axios from "axios";

const EmailInput = ({ onEmailSent, isButtonDisabled }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validateEmail = (inputEmail) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(inputEmail);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    if (!validateEmail(value)) {
      setError("Invalid email address");
    } else {
      setError("");
    }
  };

  const handleSubmit = async (event) => { 
    event.preventDefault();
    
    if (!validateEmail(email)) {
      setError("Invalid email address");
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/forgot`, {
        email,
      });

      console.log(response.data);
      setError("");
      setEmail("");
      onEmailSent(); // Notify ForgotPage that email was sent
    } catch (error) {
      console.error(error);
      setError("Error sending email");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        name="email"
        label="Please Enter your e-mail to proceed"
        variant="outlined"
        type="email"
        fullWidth
        value={email}
        onChange={handleChange}
        error={!!error}
        helperText={error}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon sx={{ color: "#E8364E" }} />
            </InputAdornment>
          ),
        }}
        sx={{
          maxWidth: "500px",
          width: "90vw",
          borderRadius: "52px",
          background: "rgba(255, 255, 255, 0.08)",
          "& label.Mui-focused": { color: "blue" },
          "& label": { color: "white" },
          "& .MuiOutlinedInput-root": {
            "& fieldset": { borderColor: "transparent" },
            "&:hover fieldset": { borderColor: "transparent" },
            "&.Mui-focused fieldset": { borderColor: "transparent" },
            "& .MuiInputBase-input": {
              color: "white",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "20.5px",
            },
          },
        }}
      />
      {isLoading && <p>Sending email...</p>}
      <div style={{ marginTop: "15px" }}>
        <button
          type="submit"
          disabled={isButtonDisabled}
          style={{
            backgroundColor: isButtonDisabled ? 'grey' : '#E8364E',
            color: 'white',
            border: 'none',
            borderRadius: '52px',
            padding: '10px 20px',
            cursor: isButtonDisabled ? 'not-allowed' : 'pointer',
          }}
        >
          Send Email
        </button>
      </div>
    </form>
  );
};

export default EmailInput;
