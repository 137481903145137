import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Logo from "../logo.svg";
import VerifyButton from "../components/VerifyButton";
import ResendButton from "../components/ResendButton";
import "../styles/VerificationPage.css";
import axios from "axios";

const VerificationPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = location.state?.email; // Extracting email from the state
  const token = queryParams.get("token");
  const apiUrl = process.env.REACT_APP_API_URL;

  const [timeLeft, setTimeLeft] = useState(60); // Initial time in seconds
  const [verificationStatus, setVerificationStatus] = useState(null);

  useEffect(() => {
    if (email && token) {
      // Call the backend API to verify the token
      axios
        .get(`${apiUrl}/verify`, { params: { email, token } })
        .then((response) => {
          //console.log("Verification response:", response.data);
          setVerificationStatus("Processing..."); // Display a processing message
          // Backend handles redirection, so we just inform the user that their request is being processed
        })
        .catch((error) => {
          console.error("Verification failed:", error);
          setVerificationStatus("Verification failed. Please try again later.");
        });
    }
  }, [email, token]);

  useEffect(() => {
    if (timeLeft <= 0) return; // Stop the timer when time is up

    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000); // Update every second

    return () => clearInterval(timerId); // Cleanup interval on component unmount
  }, [timeLeft]);

  const resetTimer = () => {
    setTimeLeft(60);
  };

  return (
    <div className="verification-page signup bg">
      <div className="logo-container1">
        <img src={Logo} className="signup-logo1" alt="Logo" />
      </div>
      <h3 className="copyright-2021-2022">MOQAH.PK</h3>

      <VerifyButton />

      <p className="verification-text">
        {verificationStatus ||
          "Verify your Email Address with the verification link we sent you on your email"}
      </p>
      <ResendButton
        email={email}
        isDisabled={timeLeft > 0}
        resetTimer={resetTimer}
      />

      <p className="resend-text">
        Request again {timeLeft > 0 ? `in ${timeLeft} seconds` : "now"}
      </p>

      <div className="image-container">
        <img
          src={`${process.env.PUBLIC_URL}/image1-1.png`}
          alt="Decorative Element 11"
          className="circular-images image11"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image2-2.png`}
          alt="Decorative Element 22"
          className="circular-images image21"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image4-4.png`}
          alt="Decorative Element 44"
          className="circular-images image41"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image5-5.png`}
          alt="Decorative Element 55"
          className="circular-images image51"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image6-6.png`}
          alt="Decorative Element 66"
          className="circular-images image61"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image7-7.png`}
          alt="Decorative Element 77"
          className="circular-images image71"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image8-8.png`}
          alt="Decorative Element 88"
          className="circular-images image81"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image9-9.png`}
          alt="Decorative Element 99"
          className="circular-images image91"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image10-10.png`}
          alt="Decorative Element 101"
          className="circular-images image101"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image11-11.png`}
          alt="Decorative Element 111"
          className="circular-images image111"
        />
      </div>
    </div>
  );
};

export default VerificationPage;
