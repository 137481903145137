import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../styles/SignupPage.css";
import "../styles/LoginPage.css";
import ResetForm from "../components/ResetForm";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Logo from "../logo.svg";
import SignInWithGoogleButton from "../components/SignInWithGoogleButton";
import ORDivider from "../components/ORDivider";

const ResetPage = () => {
  const [error, setError] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    const checkToken = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/check-token?token=${token}`
        );
        if (response.status !== 200) {
          setError("Invalid or expired token");
        }
      } catch (error) {
        setError("Invalid or expired token");
      }
    };

    checkToken();
  }, []);

  const handleLogin = (email, password) => {
    console.log("Logging in with:", email, password);
    // Implement your login logic here, e.g., authentication against a server
  };

  return (
    <div className="signup bg">
      <div className="logo-container">
        <img src={Logo} className="signup-logo" alt="Logo" />
        <h3 className="copyright-2021-2022">MOQAH.PK</h3>
      </div>

      <div className="head-text">
        <div className="text-container">
          <div className="style">
            Unleash the <span className="break-on-desktop"></span>Magic of
            Events
          </div>
          {/* Directly follow with the subHeading */}
          <div className="subHeading">
            Enter your new password details below to reset
            <div style={{ marginTop: "10px" }}>
              {error ? (
                <Typography
                  variant="body2"
                  sx={{
                    color: "red",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                  }}
                >
                  {error}
                </Typography>
              ) : (
                <ResetForm onLogin={handleLogin} />
              )}
            </div>
            <div style={{ marginTop: "15px" }}>
              <Typography
                variant="body2"
                sx={{
                  color: "#FFF",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  textDecoration: "underline",
                }}
              >
                Note: By resetting your password, you will be logged out of all
                your devices
              </Typography>
            </div>
            <div className="auth-links">
              <Typography
                variant="body2"
                component="span"
                className="not-member"
              >
                Don't have an account?{" "}
                <Link to="/signup" className="signup-link">
                  Sign up
                </Link>
              </Typography>
            </div>
            {/* <div >
              <ORDivider />
              <SignInWithGoogleButton style={{ marginTop: '-5px' }} />
            </div> */}
          </div>
        </div>
      </div>

      <div className="image-container">
        <img
          src={`${process.env.PUBLIC_URL}/image1.png`}
          alt="Decorative Element 1"
          className="circular-image image1"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image2.png`}
          alt="Decorative Element 2"
          className="circular-image image2"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image3.png`}
          alt="Decorative Element 3"
          className="circular-image image3"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image4.png`}
          alt="Decorative Element 4"
          className="circular-image image4"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image5.png`}
          alt="Decorative Element 5"
          className="circular-image image5"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image6.png`}
          alt="Decorative Element 6"
          className="circular-image image6"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image7.png`}
          alt="Decorative Element 7"
          className="circular-image image7"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image8.png`}
          alt="Decorative Element 8"
          className="circular-image image8"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image9.png`}
          alt="Decorative Element 9"
          className="circular-image image9"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image10.png`}
          alt="Decorative Element 10"
          className="circular-image image10"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image11.png`}
          alt="Decorative Element 11"
          className="circular-image image11"
        />
      </div>
    </div>
  );
};

export default ResetPage;
