import React from "react";
import PurpleButton from "./PurpleButton";

const CreateEventBanner = () => {
  const imageSrc = process.env.PUBLIC_URL + "make-event.png";
  return (
    <div className="create-event-banner-container">
      <div className="items-container">
        <div className="image-container">
          <img src={imageSrc} alt="Image" className="image" />
        </div>
        <div className="content-container">
          <h2 className="heading">Make your own event</h2>
          <h4 className="subheading">
            Have your own event listed on our platform in just few simple steps.
          </h4>
          <PurpleButton dest={"/add-event"} content={"Create Events"} />
        </div>
      </div>
    </div>
  );
};

export default CreateEventBanner;
