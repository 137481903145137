import React from 'react';
import Button from '@mui/material/Button';

const LoginNow = () => {
  return (
    <Button
      sx={{
        color: 'var(--Neutral-10, #FFF)', // Use CSS variable with fallback
        fontFamily: 'Inter', // Specify the font family
        fontSize: '16px', // Set font size
        fontWeight: 600, // Set the font weight
        padding: '8px 16px', // Add some padding for better sizing
        borderRadius: '6px', // Round the corners slightly
        background: '#E8364E', // Set a background color
        margintop: 20 ,
        '&:hover': {
          backgroundColor: '#cf2e3d', // Change background on hover
        },
        width: '75%', // Use 100% width on smaller screens
        margin: 'auto', // Center the button
        display: 'block', // Ensure the button is a block element

        // Media queries for responsiveness
        '@media (min-width: 600px)': { // For tablets and above
          width: '604px', // Set a fixed width
        },
        '@media (min-width: 1400px)': { // For large desktops
          position: 'relative', // Positioning context
          right: '-200px', // Move the button 200px to the left from its normal position
        }
      }}
    >
      Login Now
    </Button>
  );
};

export default LoginNow;
