import React, { useEffect, useState, useRef } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useParams } from "react-router-dom";
import axios from "axios";
import EventDetailsBanner from "../components/EventDetailsBanner";
import EventDetailsMain from "../components/EventDetailsMain";
import LoadingBox from "../components/LoadingBox";

const EventDetails = () => {
  const eventDetailsMainRef = useRef(null);
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await axios.get(`${apiUrl}/event-details/${id}`);
        const eventData = response.data;

        // // Format event name for URL
        // const eventName = eventData.name.replace(/\s+/g, '-').toLowerCase();
        // const newUrl = `/${eventName}`;

        // // Update the URL in the browser's address bar without redirecting
        // if (window.location.pathname !== newUrl) {
        //   window.history.pushState(null, "", newUrl);
        // }

        // Process event data and set state
        let parsedLinks = {};
        try {
          parsedLinks = JSON.parse(eventData.links);
        } catch (error) {
          console.error("Failed to parse links:", error);
        }

        const checkImageExists = (url) => {
          return new Promise((resolve) => {
            const img = new Image();
            img.src = url;
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
          });
        };

        const isValidImage = await checkImageExists(eventData.adv_banner);
        const backgroundImage = isValidImage
          ? eventData.adv_banner
          : "../../Adv_imgs/moqah_bg.png";

        setEvent({ ...eventData, parsedLinks, backgroundImage });
      } catch (error) {
        console.error("Error fetching event details:", error);
      }
    };

    fetchEventDetails();
  }, [id]);

  const formatDuration = (duration) => {
    const hours = Math.floor(duration);
    const minutes = Math.round((duration - hours) * 60);
    return `${hours}h ${minutes}min`;
  };

  return (
    <>
      <Header searchBar={false} />
      <div>
        {event ? (
          <>
            <EventDetailsBanner
              data={{
                backgroundImage: event.backgroundImage,
                heading: event.name,
                location: event.city,
                date: event.date,
                time: event.time,
                buttonContent1: event.cta_button_text1,
                buttonLink1: event.cta_button_link1,
                buttonContent2: event.cta_button_text2,
                buttonLink2: event.cta_button_link2,
                venue: event.venue,
              }}
            />
            <EventDetailsMain
              id="event-details-main"
              ref={eventDetailsMainRef}
              data={{
                heading: event.name,
                location: event.location,
                ticket_price: Math.min(...Object.values(event.ticket_price)),
                date: event.date,
                time: event.time,
                summary: event.short_summary,
                description: event.details,
                duration: formatDuration(event.duration),
                audience: event.audience,
                venue: event.venue,
                tags: event.tags,
                organizerDetails: {
                  name: event.org_name,
                  contact: event.contact,
                  link: event.parsedLinks,
                },
              }}
            />
          </>
        ) : (
          <LoadingBox />
        )}
      </div>
      <Footer />
    </>
  );
};

export default EventDetails;
