import React, { useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import LockIcon from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import SignInButton from '../components/SignInButton';

const ResetForm = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({ password: '', confirmPassword: '' });
  const location = useLocation();
  const navigate = useNavigate(); // Initialize useNavigate
  const token = new URLSearchParams(location.search).get('token');

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle the visibility state
  };

  const validatePassword = (password) => {
    if (!password) return "Password cannot be empty";
    return password.length < 8 ? "Password must be at least 8 characters long" : '';
  };

  const validateConfirmPassword = (password, confirmPassword) => {
    if (!confirmPassword) return "Confirm Password cannot be empty";
    return password === confirmPassword ? '' : "Passwords do not match";
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let errorMessages = { ...errors };

    if (name === "password") {
      setPassword(value);
      // Clear error message while typing
      setErrors((prevErrors) => ({ ...prevErrors, password: '' }));
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
      // Clear error message while typing
      setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: '' }));
    }

    setErrors(errorMessages);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const passwordError = validatePassword(password);
    const confirmPasswordError = validateConfirmPassword(password, confirmPassword);

    if (!passwordError && !confirmPasswordError) {
      navigate('/loader'); // Navigate to /loader immediately after form submission

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/reset`, {
          token,
          newPassword: password,
        });
        console.log('Password reset successful:', response.data);
        navigate('/reset1'); // Navigate to /reset1 on success
      } catch (error) {
        console.error('Error resetting password:', error);
        // Handle error here, you might want to navigate to an error page or show an error message
      }
    } else {
      setErrors({ password: passwordError, confirmPassword: confirmPasswordError });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="reset-form">
      <TextField
        name="password"
        label="Password"
        variant="outlined"
        type={showPassword ? 'text' : 'password'}
        fullWidth
        error={!!errors.password}
        sx={{
          borderRadius: '50px',
          background: 'rgba(255, 255, 255, 0.08)',
          marginBottom: '1rem',
          '& label.Mui-focused': { color: 'blue' },
          '& label': { color: 'white' },
          '& .MuiOutlinedInput-root': {
            '& fieldset': { borderColor: 'transparent' },
            '&:hover fieldset': { borderColor: 'transparent' },
            '&.Mui-focused fieldset': { borderColor: 'transparent' },
            '& .MuiInputBase-input': {
              color: 'white',
            }
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon sx={{ color: '#E8364E' }} />
            </InputAdornment>
          ),
          endAdornment: (
            <>
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={togglePasswordVisibility}
                  edge="end"
                  sx={{ color: 'white' }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
              {errors.password && (
                <InputAdornment position="end">
                  <span style={{ color: 'red', marginLeft: '10px', fontSize: '0.75rem' }}>{errors.password}</span>
                </InputAdornment>
              )}
            </>
          ),
        }}
        onChange={handleChange}
        value={password}
      />

      <TextField
        name="confirmPassword"
        label="Confirm Password"
        variant="outlined"
        type={showPassword ? 'text' : 'password'}
        fullWidth
        error={!!errors.confirmPassword}
        sx={{
          borderRadius: '50px',
          background: 'rgba(255, 255, 255, 0.08)',
          '& label.Mui-focused': { color: 'blue' },
          '& label': { color: 'white' },
          '& .MuiOutlinedInput-root': {
            '& fieldset': { borderColor: 'transparent' },
            '&:hover fieldset': { borderColor: 'transparent' },
            '&.Mui-focused fieldset': { borderColor: 'transparent' },
            '& .MuiInputBase-input': {
              color: 'white',
            }
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon sx={{ color: '#E8364E' }} />
            </InputAdornment>
          ),
          endAdornment: (
            <>
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={togglePasswordVisibility}
                  edge="end"
                  sx={{ color: 'white' }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
              {errors.confirmPassword && (
                <InputAdornment position="end">
                  <span style={{ color: 'red', marginLeft: '10px', fontSize: '0.75rem' }}>{errors.confirmPassword}</span>
                </InputAdornment>
              )}
            </>
          ),
        }}
        onChange={handleChange}
        value={confirmPassword}
      />
      <div style={{ marginTop: '10px' }}>
        <SignInButton buttonText="Reset" type="submit" />
      </div>
    </form>
  );
};

export default ResetForm;
