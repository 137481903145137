import React from "react";

const MapButton = ({ data }) => {
  const iconPath = process.env.PUBLIC_URL + "/icons/";
  console.log(data.text);
  return (
    <div className="map-button" onClick={data.onClick}>
      <img src={iconPath + "map-pin.svg"} />
      <p className="button-text">{data.text}</p>
    </div>
  );
};

export default MapButton;
