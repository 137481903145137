import React from 'react';
import '../styles/privacy.css';  // Import the CSS for styling
import Logo from "../logo.svg"


function PrivacyPage() {
  console.log("Imported logo path: ", Logo); // This will show the path or data URL for the SVG
  return (
    <div className="coming-soon-page">
    <header className="coming-soon-header">
      <img src={Logo} alt="Logo" className="logo" />
      <p className="coming-soon-text">Moqah.pk</p>
    </header>
    <main className="content-area">
        <h1>Privacy Policy </h1>

    </main>
    <footer className="coming-soon-footer">
      {/* Footer content goes here */}
    </footer>
  </div>
  );
}

export default PrivacyPage;