import React from "react";
import { useFilter } from "../context/FilterContext";

const CitiesCard = ({ imageUrl, heading, link, active }) => {
  const { filterDispatch } = useFilter();

  const handleClick = () => {
    if (!active) {
      filterDispatch({
        type: "LOCATION",
        payload: { option: heading, check: true },
      });
    }
  };

  const isIslamabad = heading.toLowerCase() === "islamabad";
  const cardClass = isIslamabad ? "city-card" : "city-card city-card-disabled";

  return (
    <div className={active ? "city-card-active" : ""}>
      <div
        className={cardClass}
        style={{ backgroundImage: `url(${imageUrl})` }}
        onClick={isIslamabad ? handleClick : () => {}}
      >
        <h3 className="city-card__heading">{heading}</h3>
        {!isIslamabad && <div className="city-card__overlay">Coming Soon</div>}
      </div>
    </div>
  );
};

export default CitiesCard;
