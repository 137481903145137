import React from 'react';
import '../styles/ResetSuccessPage.css';
import '../styles/imagestyle.css';
import Logo from '../logo.svg';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


const LoaderPage = () => {
  return (
    <div className="signup bg">
      <div className="logo-container">
        <img src={Logo} className="signup-logo" alt="Logo" />
        <h3 className="copyright-2021-2022">MOQAH.PK</h3>
      </div>
  
      <div className="head-text">
        <div className="text-container">
          <div className="style">
            Unleash the <span className="break-on-desktop"></span>Magic of Events
          </div>
        </div>
        <div className="reset-message">
        Getting things<br/> ready for you
        <Typography
          sx={{
            color: 'var(--Neutral-10, #FFF)',
            fontFamily: 'Inter',
            fontSize: '25px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal',
            textAlign: {
              xs: 'center', // Center on small screens
              sm: 'center', // Center on tablets
              md: 'left', // Left-align on larger screens
            },
            marginTop: '25px',
            marginLeft: {
              xs: '0', // No margin on small screens
              sm: '0', // No margin on tablets
              md: '0px', // Add margin-left on larger screens if needed
            },
          }}
        >
          Please wait...
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
          <CircularProgress sx={{ color: '#E8364E' }} />
        </Box>
        
          </div>
          
          
      </div>
      <div>
    </div>

          <div className="image-container">
        <img src={`${process.env.PUBLIC_URL}/image1.png`} alt="Decorative Element 1" className="circular-image image1" />
        <img src={`${process.env.PUBLIC_URL}/image2.png`} alt="Decorative Element 2" className="circular-image image2" />
        <img src={`${process.env.PUBLIC_URL}/image3.png`} alt="Decorative Element 3" className="circular-image image3" />
        <img src={`${process.env.PUBLIC_URL}/image4.png`} alt="Decorative Element 4" className="circular-image image4" />
        <img src={`${process.env.PUBLIC_URL}/image5.png`} alt="Decorative Element 5" className="circular-image image5" />
        <img src={`${process.env.PUBLIC_URL}/image6.png`} alt="Decorative Element 6" className="circular-image image6" />
        <img src={`${process.env.PUBLIC_URL}/image7.png`} alt="Decorative Element 7" className="circular-image image7" />
        <img src={`${process.env.PUBLIC_URL}/image8.png`} alt="Decorative Element 8" className="circular-image image8" />
        <img src={`${process.env.PUBLIC_URL}/image9.png`} alt="Decorative Element 9" className="circular-image image9" />
        <img src={`${process.env.PUBLIC_URL}/image10.png`} alt="Decorative Element 10" className="circular-image image10" />
        <img src={`${process.env.PUBLIC_URL}/image11.png`} alt="Decorative Element 11" className="circular-image image11" />

      </div>
      
    </div>
  );
};

export default LoaderPage;
