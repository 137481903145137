import React, { useRef, useState } from "react";
import DarkButton from "./DarkButton";
import PrimaryButton from "./PrimaryButton";

const ButtonList = ({ heading, buttons }) => {
  const iconsPath = process.env.PUBLIC_URL + "/icons/";
  const scrollRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(0);
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleScroll = (scrollOffset) => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += scrollOffset;
    }
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollRef.current.offsetLeft);
    setScrollLeft(scrollRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust scrolling speed
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div className="button-list-container">
      <div className="heading-container">
        <h6 className="heading">{heading}</h6>
        <div className="scroll-buttons">
          <button
            className="scroll-left"
            onClick={() => handleScroll(-100)}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
            <img src={iconsPath + "arrow-left.svg"} />
          </button>
          <button
            className="scroll-right"
            onClick={() => handleScroll(100)}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
            <img src={iconsPath + "arrow-right.svg"} />
          </button>
{/* 
          <PrimaryButton content={"See More"} dest={`/discover`} />
           */}
        </div>
      </div>
      <hr className="horizontal-line" />
      <div
        className="button-scroll-container"
        ref={scrollRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        {buttons && buttons.length > 0 && (
          <div className="button-wrapper">
            <div style={{ marginRight: "1.85em" }}></div>
            {buttons.map((button, index) => (
              <DarkButton
                key={index}
                content={button.text}
                dest={button.link}
                className={`dark-button ${
                  button.text !== "Islamabad" && button.text !== "Rawalpindi" ? "grey" : ""
                }`}
                
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ButtonList;
