import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/ResetSuccessPage.css';
import '../styles/imagestyle.css';
import Logo from '../logo.svg';
import LoginNow from '../components/LoginNow';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const LoginGoogle = styled(Button)(({ theme }) => ({
  backgroundColor: '#404040',
  color: '#fff',
  fontFamily: 'Inter',
  borderRadius: '6px', // Adjusted to match LoginNow button
  gap: '10px',
  position: 'relative', // Adjust position to relative
  width: '45%', // Adjust width to match LoginNow button
  margin: 'auto', // Center the button horizontally
  marginTop: '20px', // Adjust marginTop for spacing from LoginNow button
  '&:hover': {
    backgroundColor: '#585858',
  },
  [theme.breakpoints.down('sm')]: {
    width: '75%',
    marginTop: '20px', // Adjust marginTop for spacing on smaller screens
    marginLeft: '0',
    right: '-50px',
  },
}));
const GoogleIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M21.8055 10.0415H21V10H12V14H17.6515C16.827 16.3285 14.6115 18 12 18C8.6865 18 6 15.3135 6 12C6 8.6865 8.6865 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z" fill="#FFC107"/>
<path d="M3.15283 7.3455L6.43833 9.755C7.32733 7.554 9.48033 6 11.9998 6C13.5293 6 14.9208 6.577 15.9803 7.5195L18.8088 4.691C17.0228 3.0265 14.6338 2 11.9998 2C8.15883 2 4.82783 4.1685 3.15283 7.3455Z" fill="#FF3D00"/>
<path d="M12.0002 22C14.5832 22 16.9302 21.0115 18.7047 19.404L15.6097 16.785C14.5719 17.5742 13.3039 18.001 12.0002 18C9.39916 18 7.19066 16.3415 6.35866 14.027L3.09766 16.5395C4.75266 19.778 8.11366 22 12.0002 22Z" fill="#4CAF50"/>
<path d="M21.8055 10.0415H21V10H12V14H17.6515C17.2571 15.1082 16.5467 16.0766 15.608 16.7855L15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z" fill="#1976D2"/>
</svg>
);

const ResetSuccessPage = () => {
  return (
    <div className="signup bg">
      <div className="logo-container">
        <img src={Logo} className="signup-logo" alt="Logo" />
        <h3 className="copyright-2021-2022">MOQAH.PK</h3>
      </div>
  
      <div className="head-text">
        <div className="text-container">
          <div className="style">
            Unleash the <span className="break-on-desktop"></span>Magic of Events
          </div>
        </div>
        <div className="reset-message">
            Your password<br/>was successfully<br/>reset
          </div>
      </div>
      <div>
          <div className="button-container">
          <Link to="/login" style={{ textDecoration: 'none' }}>
              <LoginNow style={{ marginTop: '30px' }} />
          </Link>            
          {/* <LoginGoogle variant='contained' startIcon={<GoogleIcon />}>
              Login with Google
            </LoginGoogle> */}
            </div>
          </div>

          <div className="image-container">
        <img src={`${process.env.PUBLIC_URL}/image1.png`} alt="Decorative Element 1" className="circular-image image1" />
        <img src={`${process.env.PUBLIC_URL}/image2.png`} alt="Decorative Element 2" className="circular-image image2" />
        <img src={`${process.env.PUBLIC_URL}/image3.png`} alt="Decorative Element 3" className="circular-image image3" />
        <img src={`${process.env.PUBLIC_URL}/image4.png`} alt="Decorative Element 4" className="circular-image image4" />
        <img src={`${process.env.PUBLIC_URL}/image5.png`} alt="Decorative Element 5" className="circular-image image5" />
        <img src={`${process.env.PUBLIC_URL}/image6.png`} alt="Decorative Element 6" className="circular-image image6" />
        <img src={`${process.env.PUBLIC_URL}/image7.png`} alt="Decorative Element 7" className="circular-image image7" />
        <img src={`${process.env.PUBLIC_URL}/image8.png`} alt="Decorative Element 8" className="circular-image image8" />
        <img src={`${process.env.PUBLIC_URL}/image9.png`} alt="Decorative Element 9" className="circular-image image9" />
        
      </div>
      
    </div>
  );
};

export default ResetSuccessPage;
