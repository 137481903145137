import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

// Helper function to validate email format
const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

const ResendButton = ({ email, isDisabled, resetTimer }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const apiUrl = process.env.REACT_APP_API_URL;
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const handleResendEmail = async () => {
    if (!email || !validateEmail(email)) {
      // console.log("email : ", email)
      // console.log("valifdateemail : ", email)
      setSnackbarMessage("Invalid email address.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}/resend-email`, { email });
      if (response.status === 200) {
        setSnackbarMessage("Verification email resent successfully.");
        setSnackbarSeverity("success");
        resetTimer(); // Reset the timer after successful email resend
      } else {
        setSnackbarMessage("Failed to resend verification email.");
        setSnackbarSeverity("error");
      }
    } catch (error) {
      console.error("Error resending verification email:", error);
      setSnackbarMessage("An error occurred while resending the email.");
      setSnackbarSeverity("error");
    }
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Button
        onClick={handleResendEmail}
        disabled={isDisabled}
        sx={{
          position: "absolute",
          top: {
            xs: "33%",
            sm: "35%",
            md: "36%",
            lg: "80%",
            xl: "76%",
          },
          left: {
            xs: "18%",
            sm: "30%",
            md: "30%",
            lg: "34%",
            xl: "37%",
          },
          width: {
            xs: "70%",
            sm: "40%",
            md: "40%",
            lg: "35%",
            xl: "33%",
          },
          height: {
            xs: "60px",
            sm: "70px",
            md: "70px",
            lg: "60px",
          },
          borderRadius: "5px",
          backgroundColor: isDisabled ? "grey" : "var(--Primary, #7848F4)",
          cursor: isDisabled ? "not-allowed" : "pointer",
          flexShrink: 0,
          padding: {
            xs: "10px 20px",
            sm: "15px 30px",
            md: "20px 40px",
            lg: "25px 50px",
          },
          "&:hover": {
            backgroundColor: isDisabled ? "grey" : "var(--Primary, #7848F4)",
          },
        }}
      >
        <Typography
          sx={{
            color: "#000",
            textAlign: "center",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Inter",
            fontSize: {
              xs: "13px",
              sm: "14px",
              md: "16px",
              lg: "18px",
              xl: "20px",
            },
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "24px",
            letterSpacing: "0.15px",
            textTransform: "none",
          }}
        >
          Didn't get our email?
        </Typography>
        <Typography
          sx={{
            color: "#FF6700",
            textAlign: "center",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Inter",
            fontSize: {
              xs: "12px",
              sm: "14px",
              md: "16px",
              lg: "18px",
              xl: "20px",
            },
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "24px",
            letterSpacing: "0.15px",
            textDecorationLine: "underline",
            textTransform: "none",
            marginLeft: "10px",
          }}
        >
          Resend now
        </Typography>
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ResendButton;
