import React from "react";

const TagsList = ({ tags }) => {
  const data = tags.split(",");
  // const data = [
  //   "hello there",
  //   "amazing big event",
  //   "there will be food",
  //   "three day event",
  // ];
  return (
    <div className="tags-container">
      {data && data.length > 0 && (
        <div
          className="tag-wrapper"
          style={{ display: "flex", alignItems: "center" }}
        >
          {data.map((tag, index) => (
            <div
              key={index}
              style={{
                // display: 'flex',
                // alignItems: 'center',
                // marginRight: '0.5em', // Adjust spacing between tags
                backgroundColor: "#f0f0f0", // Light grey background
                padding: "5px 10px", // Adjust padding for better appearance
                borderRadius: "3px", // Optional: slightly rounded corners
              }}
            >
              {tag}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TagsList;
