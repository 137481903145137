import { createContext, useContext, useReducer} from "react";
import { inputFilterReducer, filterReducer} from "../reducer/FilterReducer";

const FilterContext = createContext();

const FilterProvider = ({children}) => {

    const [{sort, type, loc, cat, price}, filterDispatch] = useReducer(filterReducer, {
        sort: "",
        type: [],
        loc: ["Islamabad","Rawalpindi"],
        cat: [],
        price: [0, 100000],
    })

    return (
        <FilterContext.Provider value={{sort, type, loc, cat, price, filterDispatch}}>{children}</FilterContext.Provider>
    )
}

export const useFilter = () => useContext(FilterContext);


const InputFilterContext = createContext();
const InputFilterProvider = ({children}) => {
    const [{searchTerm, location, date}, inputFilterDispatch] = useReducer(inputFilterReducer, {
        searchTerm: "",
        location: "",
        date: null,
    })
    // const [{searchTerm, location, date}] = {
    //     searchTerm: "",
    //     location: "",
    //     date: null,
    // }
    console.log(searchTerm);
    return (
        <InputFilterContext.Provider value={{searchTerm, location, date, inputFilterDispatch}}>{children}</InputFilterContext.Provider>
    )
}

export const useInputFilter = () => useContext(InputFilterContext);
export { FilterProvider, InputFilterProvider }