import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { FcGoogle } from "react-icons/fc";

const GoogleSignInButton = styled(Button)(({ theme }) => ({
  // Ensure theme is accessed here
  backgroundColor: "transparent",
  color: "#FFF",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 400,
  textDecoration: "underline",
  textTransform: "none",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));

const SignInWithGoogleButton = () => {
  return (
    <GoogleSignInButton
      startIcon={<FcGoogle />}
      onClick={() => console.log("Sign in with Google clicked")}
    >
      Sign In with Google
    </GoogleSignInButton>
  );
};

export default SignInWithGoogleButton;
