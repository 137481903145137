import React from "react";

const CityCard = ({ imageUrl, heading, subheading, destination }) => {
  const iconsPath = process.env.PUBLIC_URL + "/icons/";
  const buttonClass = (heading === "Islamabad" || heading === "Rawalpindi") 
  ? "explore-button" 
  : "explore-button-grey";

const containerClass = (heading === "Islamabad" || heading === "Rawalpindi") 
  ? "city-card-container" 
  : "city-card-container city-card-container-grey";

  return (
    <div className={containerClass}>
      <a href={destination}>
        <div className="image-wrapper">
          <img src={imageUrl} alt="City Image" className="city-card-image" />
          <button className={buttonClass}>Explore</button>
        </div>
        <div className="city-card-content">
          <h2 className="city-card-heading">{heading}</h2>
          <div className="subhead-container">
            <p className="city-card-subheading">{subheading}</p>
            <a href="https://instagram.com"><img src={iconsPath + "options-button.svg"} alt="Options"/></a>
          </div>
        </div>
      </a>
    </div>
  );
};

export default CityCard;
