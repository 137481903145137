import React, { useState } from 'react';
import LoginForm from '../components/LoginForm';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Logo from '../logo.svg';
// import SignInWithGoogleButton from '../components/SignInWithGoogleButton';
// import ORDivider from '../components/ORDivider';
import '../styles/SignupPage.css';

const LoginPage = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });

  const handleFormChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="signup bg">
      <div className="logo-container">
        <img src={Logo} className="signup-logo" alt="Logo" />
        <h3 className="copyright-2021-2022">MOQAH.PK</h3>
      </div>

      <div className="head-text">
        <div className="text-container">
          <div className="style">
            Unleash the <span className="break-on-desktop"></span>Magic of Events
          </div>
          <div className="subHeading"> 
            Enter your login details below to access
            <LoginForm onChange={handleFormChange} />
            <div style={{ marginTop: '10px' }}></div>
            <div className="auth-links">
              <Typography variant="body2" component="span" className="not-member">
                Don't have an account? <Link to="/signup" className="signup-link">Sign up</Link>
              </Typography>
              <Typography variant="body2" component="span" className="forgot-password">
                <Link to="/forgot" className="password-link">Forgot your password?</Link>
              </Typography>
            </div>
            {/* <ORDivider />
            <SignInWithGoogleButton style={{ marginTop: '-5px' }} /> */}
          </div>
        </div>
      </div>

      <div className="image-container">
        <img src={`${process.env.PUBLIC_URL}/image1.png`} alt="Decorative Element 1" className="circular-image image1" />
        <img src={`${process.env.PUBLIC_URL}/image2.png`} alt="Decorative Element 2" className="circular-image image2" />
        <img src={`${process.env.PUBLIC_URL}/image3.png`} alt="Decorative Element 3" className="circular-image image3" />
        <img src={`${process.env.PUBLIC_URL}/image4.png`} alt="Decorative Element 4" className="circular-image image4" />
        <img src={`${process.env.PUBLIC_URL}/image5.png`} alt="Decorative Element 5" className="circular-image image5" />
        <img src={`${process.env.PUBLIC_URL}/image6.png`} alt="Decorative Element 6" className="circular-image image6" />
        <img src={`${process.env.PUBLIC_URL}/image7.png`} alt="Decorative Element 7" className="circular-image image7" />
        <img src={`${process.env.PUBLIC_URL}/image8.png`} alt="Decorative Element 8" className="circular-image image8" />
        <img src={`${process.env.PUBLIC_URL}/image9.png`} alt="Decorative Element 9" className="circular-image image9" />
        <img src={`${process.env.PUBLIC_URL}/image10.png`} alt="Decorative Element 10" className="circular-image image10" />
        <img src={`${process.env.PUBLIC_URL}/image11.png`} alt="Decorative Element 11" className="circular-image image11" />
      </div>
    </div>
  );
};

export default LoginPage;
