import React from "react";
import PurpleButton from "./PurpleButton";

const MapPreview = ({ className, mapLink }) => {
  const baseUrl = "https://www.google.com/maps/search/?api=1&query=";
  const query = encodeURIComponent(mapLink);
  const googleMapsUrl = `${baseUrl}${query}`;

  // console.log('Google Maps URL: ', googleMapsUrl);

  return (
    <div className={`${className} map-preview-container`}>
      <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
        <img className="map-image" src="/map.png" alt="Map preview" />
      </a>
      <div className="description-container">
        <PurpleButton dest={googleMapsUrl} content="Maps" />
      </div>
    </div>
  );
};

export default MapPreview;
