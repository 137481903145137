// export const getItemsByType = (items, types) =>
//   types.length > 0 ? items.filter((item) => types.includes(item.type)) : items;

export const getItemsByType = (categories, types) => {
  return categories.map((category) => ({
    ...category,
    events:
      types.length > 0
        ? category.events.filter((event) => types.includes(event.type))
        : category.events,
  }));
};

// export const getItemsByLocation = (items, locations) =>
//   locations.length > 0
//     ? items.filter((item) => locations.includes(item.city))
//     : items;

export const getItemsByLocation = (categories, locations) => {
  return categories.map((category) => ({
    ...category,
    events:
      locations.length > 0
        ? category.events.filter((event) => locations.includes(event.city))
        : category.events,
  }));
};

// export const getItemsByCategory = (items, categories) =>
//   categories.length > 0
//     ? items.filter((item) => categories.includes(item.category))
//     : items;

export const getItemsByCategory = (categories, selectedCategories) => {
  return selectedCategories.length > 0
    ? categories.filter((category) =>
        selectedCategories.includes(category.category)
      )
    : categories;
};

// export const getItemsByPriceRange = (items, priceRange) => {
//   const [minPrice, maxPrice] = priceRange;
//   return items.filter((item) => {
//     // console.log("Event Data:", item);

//     // Check if ticket_price_min and ticket_price_max are valid numbers
//     // if (typeof item.ticket_price !== 'number' || typeof item.ticket_price1 !== 'number') {
//     //   console.warn(`Invalid ticket price range for event: ${item.name}`);
//     //   return false; // Skip this item if ticket price range is not valid
//     // }

//     // console.log("Event:", item.name);
//     // console.log("Ticket Price Min:", item.ticket_price);
//     // console.log("Ticket Price Max:", item.ticket_price1);
//     // console.log("Price Range:", minPrice, "-", maxPrice);

//     // Filter based on whether the price range overlaps with the event's price range
//     // ticket_price = min and ticket_price1 = max
//     // const inRange =
//     //   item.ticket_price >= minPrice && item.ticket_price1 <= maxPrice;
//     var inRange = false;
//     // console.log(item.ticket_prices);
//     item.ticket_prices.map((price) => {
//       if (price >= minPrice && price <= maxPrice) {
//         // console.log('in range' + item.name);
//         inRange = true;
//         return true;
//       }
//     });
//     // console.log("In Range:", inRange);
//     return inRange;
//   });
// };

export const getItemsByPriceRange = (categories, priceRange) => {
  const [minPrice, maxPrice] = priceRange;
  return categories.map((category) => ({
    ...category,
    events: category.events.filter((event) => {
      return event.ticket_prices.some(
        (price) => price >= minPrice && price <= maxPrice
      );
    }),
  }));
};

// export const getItemsByPriceSort = (items, type) => {
//   if (type.toLowerCase() === "lth") {
//     return [...items].sort((a, b) => a.ticket_price - b.ticket_price);
//   } else if (type.toLowerCase() === "htl") {
//     return [...items].sort((a, b) => b.ticket_price - a.ticket_price);
//   }
//   return items;
// };

export const getItemsByPriceSort = (categories, sortType) => {
  return categories.map((category) => ({
    ...category,
    events:
      sortType.toLowerCase() === "lth"
        ? [...category.events].sort((a, b) => a.ticket_price - b.ticket_price)
        : sortType.toLowerCase() === "htl"
        ? [...category.events].sort((a, b) => b.ticket_price - a.ticket_price)
        : category.events,
  }));
};

export const categorizeEvents = (events) => {
  return events.reduce((accumulator, event) => {
    // Find if the category already exists in the accumulator
    let categoryGroup = accumulator.find(
      (group) => group.category === event.category
    );

    if (!categoryGroup) {
      // If not, create a new category group
      categoryGroup = { category: event.category, items: [] };
      accumulator.push(categoryGroup);
    }

    // Add the event to the appropriate category group
    categoryGroup.items.push(event);
    return accumulator;
  }, []);
};

// export const inputFilter = (items, searchParam, date, location) => {
//     let searchParamFilterResults, dateFilterResults, locationFilterResults;

//     if (searchParam) {
//         searchParamFilterResults = items.filter(item =>
//             item.name.toLowerCase().includes(searchParam.toLowerCase()) ||
//             item.location.toLowerCase().includes(searchParam.toLowerCase())
//         );
//     } else {
//         searchParamFilterResults = items;
//     }

//     if (date) {
//         dateFilterResults = searchParamFilterResults.filter(item =>
//             item.date.getDate() === date.getDate() &&
//             item.date.getMonth() === date.getMonth() &&
//             item.date.getFullYear() === date.getFullYear()
//         );
//     } else {
//         dateFilterResults = searchParamFilterResults;
//     }

//     if (location) {
//         locationFilterResults = dateFilterResults.filter(item =>
//             item.location.toLowerCase().includes(location.toLowerCase()) ||
//             item.zipCode === location
//         );
//     } else {
//         locationFilterResults = dateFilterResults;
//     }

//     return locationFilterResults;
// };
