import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import SecondaryButton from "./SecondaryButton";
import SearchForm from "./SearchForm";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../logo.svg";
import axios from "axios"; // Import axios for making API calls
import Tooltip from "./Tooltip";
import PurpleButton from "./PurpleButton";

const Header = ({ searchBar }) => {
  const [userDetails, setUserDetails] = useState(null); // State to store user details
  const [isNavigating, setIsNavigating] = useState(false); // State for handling navigation
  const [menuVisible, setMenuVisible] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const menuRef = useRef(null); // Ref for the menu container
  const hamburgerRef = useRef(null); // Ref for the hamburger button
  const iconsPath = process.env.PUBLIC_URL + "/icons/";
  const apiUrl = process.env.REACT_APP_API_URL;
  
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await axios.post(`${apiUrl}/verify-token`, {
            token,
          });
          if (response.status === 200) {
            setUserDetails(response.data.userDetails);
            console.log(response.data.userDetails);
          } else {
            console.log("Failed to verify token");
            setUserDetails(undefined);
          }
        } catch (error) {
          console.log("Error verifying token:", error);
          setUserDetails(undefined);
        }
      } else {
        setUserDetails(undefined);
      }
    };

    fetchUserDetails();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !hamburgerRef.current.contains(event.target)
      ) {
        setMenuVisible(false);
      }
    };

    // Attach event listeners
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside); // To handle touch events

    // Clean up event listeners
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);

  const handleMouseEnter = (e) => {
    setTooltipPosition({ x: e.clientX, y: e.clientY });
    setTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  const handleMouseMove = (e) => {
    setTooltipPosition({ x: e.clientX, y: e.clientY });
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleNavigation = (path) => {
    setIsNavigating(true);
    setTimeout(() => {
      navigate(path);
      setIsNavigating(false);
    }, 500); // 0.5-second delay for transition
  };

  const toggleMenu = (event) => {
    event.stopPropagation(); // Prevent the click event from propagating to the document
    setMenuVisible(!menuVisible);
  };

  const fadeTransition = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.5 },
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={fadeTransition}
    >
      <div
        className={`header-top-fixed one-page-nav top-0 left-0 right-0 py-4 z-50`}
      >
        <div className="flex justify-between items-center navbar">
          <Link to="../home">
            <div className="logo">
              <img src={Logo} alt="MOQAH.Pk" />
              <h6>MOQAH.Pk</h6>
            </div>
          </Link>
          <div className="menu-container">
            <button
              ref={hamburgerRef}
              className="hamburger-menu"
              onClick={toggleMenu}
            >
              ☰
            </button>
            <ul
              ref={menuRef}
              className={`main-menu ${menuVisible ? "show" : ""}`}
            >
              <li className="mobile-auth-option">
                {userDetails === undefined ? (
                  <a onClick={handleLogin}>Login</a>
                ) : (
                  <a onClick={handleLogout}>Logout</a>
                )}
              </li>
              <li>
                <a
                  href="/discover"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                >
                  Trending Events
                </a>
              </li>
              <li>
                <a
                  href="/discover"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                >
                  Categories
                </a>
              </li>
              <li>
                <a
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                  className="disabled-option"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onMouseMove={handleMouseMove}
                >
                  Cities
                </a>
                {tooltipVisible && (
                  <Tooltip text="Coming Soon" position={tooltipPosition} />
                )}
              </li>
              <PurpleButton dest={"/add-event"} content={"Create your Event"} />
            </ul>

            {userDetails === undefined ? (
              <div></div>
            ) : (
              <div className="vertical-line"></div>
            )}
            
            <ul className="profile">
              <li>
                {userDetails ? (
                  <a
                    href={`https://www.linkedin.com/in/${userDetails.username}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="profile-details">
                      <img src={iconsPath + "placeholder.png"} alt="Profile" />
                      <div>
                        <h6 className="name">
                          {userDetails.first_name} {userDetails.last_name}
                        </h6>
                      </div>
                    </div>
                  </a>
                ) : userDetails === undefined ? (
                  <div></div>
                ) : (
                  <div className="profile-details">
                    <img src={iconsPath + "placeholder.png"} alt="Profile" />
                  </div>
                )}
              </li>
              {userDetails === undefined ? (
                <li>
                  <SecondaryButton content={"Login"} onClick={handleLogin} />
                </li>
              ) : (
                <li>
                  <SecondaryButton content={"Logout"} onClick={handleLogout} />
                </li>
              )}
            </ul>
          </div>
        </div>
        {searchBar ? <SearchForm /> : null}
      </div>
    </motion.div>
  );
};

export default Header;
