import React from 'react';

const PurpleButton = ({ dest, content }) => {
  return (
    <div
      className="purple-button inline-flex"
      style={{
        backgroundColor: '#0088cc',
        color: 'white',
        padding: '10px 20px',
        borderRadius: '5px',
        textAlign: 'center',
        cursor: 'pointer',
      }}
      onMouseEnter={(e) => {
        e.currentTarget.querySelector('h6').style.transform = 'scale(1.1)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.querySelector('h6').style.transform = 'scale(1)';
      }}
    >
      <a
        href={dest}
        target="_blank" 
        rel="noopener noreferrer"
        style={{
          textDecoration: 'none',
          color: 'inherit',
        }}
      >
        <h6
          style={{
            transition: 'transform 0.2s ease-in-out',
          }}
        >
          {content}
        </h6>
      </a>
    </div>
  );
};

export default PurpleButton;
