// SignInButton.jsx
import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#E8364E',
    borderRadius: '52px',
    color: 'white',
    width: '150px',
    height: '40px',
    flexShrink: 0,
    marginTop:'0px',
    '&:hover': {
        backgroundColor: '#cf2e3d',
    },
    [theme.breakpoints.down('sm')]: {
        width: '90%',
        height: '40px',
        borderRadius: '30px',
    }
}));

const SignInButton = ({ buttonText = 'Sign In', onLogin }) => {
    const handleClick = () => {
        if (onLogin) {
            // onLogin(); // Trigger the onLogin function passed as prop
        }
    };

    return (
        <StyledButton variant="contained" type="submit" onClick={handleClick}>
            {buttonText}
        </StyledButton>
    );
};

export default SignInButton;
