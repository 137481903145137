import React from 'react';
import { Box, TextField, Button, Checkbox, Link, IconButton, Typography, InputAdornment, FormControlLabel } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

const RegisterButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#E8364E',
  color: '#fff',
  fontFamily: 'Inter',
  borderRadius: '8px',
  gap: '10px',
  position: 'absolute',
  width: '480px',
  marginTop: '440px',
  marginLeft: '730px',
  '&:hover': {
    backgroundColor: '#CF2E3D',
  },
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    top: '20px',
    marginTop: '15rem',
    marginLeft: '0',
  },
}));

const CreateNewAccount = ({
  formData,
  showPassword,
  emailError,
  passwordError,
  nameError,
  termsError,
  handleInputChange,
  togglePasswordVisibility,
  handleSubmit,
}) => {
  const theme = useTheme();

  return (
    <Box
      className="create-new-account"
      sx={{
        flexBasis: '50%',
        fontSize: '1rem',
        fontWeight: 600,
        fontFamily: 'Inter, sans-serif',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 2,
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      }}
    >
      <Typography
        className="headingStyle"
        sx={{
          color: 'var(--Neutral-10, #FFF)',
          fontFamily: 'Inter, sans-serif',
          fontSize: '3.125rem',
          fontWeight: 600,
          textAlign: 'center',
          mt: '10rem',
          ml: '46.875rem',
          alignSelf: 'center',
          [theme.breakpoints.down('sm')]: {
            fontSize: '1.7rem',
            mt: '0rem',
            ml: '0',
          },
        }}
      >
        Create New Account
      </Typography>

      <Box className="input-wrapper" sx={{ mt: '240px', ml: '480px', width: '200px', [theme.breakpoints.down('sm')]: { width: '80%', mt: '2rem', ml: '0' } }}>
        <TextField
          type="text"
          placeholder="First Name"
          value={formData.firstName}
          onChange={handleInputChange}
          name="firstName"
          variant="standard"
          fullWidth
          InputProps={{
            disableUnderline: true,
            sx: { color: '#FFF', fontFamily: 'Inter', background: 'transparent' },
            endAdornment: nameError.firstName && (
              <InputAdornment position="end">
                <Typography variant="caption" sx={{ color: 'red', whiteSpace: 'nowrap', ml: 1 }}>
                  {nameError.firstName}
                </Typography>
              </InputAdornment>
            ),
          }}
          error={!!nameError.firstName}
        />
      </Box>

      <Box className="input-wrapper-1" sx={{ mt: '240px', ml: '980px', width: '200px', [theme.breakpoints.down('sm')]: { width: '80%', mt: '4.8rem', ml: '0' } }}>
        <TextField
          type="text"
          placeholder="Last Name"
          value={formData.lastName}
          onChange={handleInputChange}
          name="lastName"
          variant="standard"
          fullWidth
          InputProps={{
            disableUnderline: true,
            sx: { color: '#FFF', fontFamily: 'Inter', background: 'transparent' },
            endAdornment: nameError.lastName && (
              <InputAdornment position="end">
                <Typography variant="caption" sx={{ color: 'red', whiteSpace: 'nowrap', ml: 1 }}>
                  {nameError.lastName}
                </Typography>
              </InputAdornment>
            ),
          }}
          error={!!nameError.lastName}
        />
      </Box>

      <Box className="input-wrapper-2" sx={{ mt: '300px', ml: '730px', width: '450px', [theme.breakpoints.down('sm')]: { width: '80%', mt: '7rem', ml: '0' } }}>
        <TextField
          type="email"
          placeholder="E-mail"
          value={formData.email}
          onChange={handleInputChange}
          name="email"
          variant="standard"
          fullWidth
          InputProps={{
            disableUnderline: true,
            sx: { color: '#FFF', fontFamily: 'Inter', background: 'transparent' },
            endAdornment: emailError && (
              <InputAdornment position="end">
                <Typography variant="caption" sx={{ color: 'red', whiteSpace: 'nowrap', ml: 1 }}>
                  {emailError}
                </Typography>
              </InputAdornment>
            ),
          }}
          error={!!emailError}
        />
      </Box>

      <Box className="input-wrapper-3" sx={{ mt: '360px', ml: '730px', width: '450px', [theme.breakpoints.down('sm')]: { width: '80%', mt: '10rem', ml: '0' } }}>
        <TextField
          type={showPassword ? 'text' : 'password'}
          placeholder="Password"
          value={formData.password}
          onChange={handleInputChange}
          name="password"
          variant="standard"
          fullWidth
          InputProps={{
            disableUnderline: true,
            sx: { color: '#FFF', fontFamily: 'Inter', background: 'transparent' },
            endAdornment: (
              <>
                <IconButton onClick={togglePasswordVisibility}>
                  {showPassword ? <VisibilityOff style={{ color: '#9E9E9E' }} /> : <Visibility style={{ color: '#9E9E9E' }} />}
                </IconButton>
                {passwordError && (
                  <Typography variant="caption" sx={{ color: 'red', whiteSpace: 'nowrap', ml: 1 }}>
                    {passwordError}
                  </Typography>
                )}
              </>
            ),
          }}
          error={!!passwordError}
        />
      </Box>

      <Box className="terms-cond" sx={{ mt: '160px', ml: '680px', [theme.breakpoints.down('sm')]: { width: '80%', fontSize: '9px', mt: '13.5rem', ml: '0' } }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.agreeToTerms}
              onChange={handleInputChange}
              name="agreeToTerms"
              id="agree-checkbox"
              sx={{ color: termsError ? '#FF0000' : '#FFF', mr: 1 }}
            />
          }
          label={
            <Typography style={{ color: '#FFF', fontFamily: 'Inter', fontSize: '14px', fontWeight: 500 }}>
              I agree with <Link component={RouterLink} to="/terms-cond" className="login-link">Terms of Service</Link> and <Link component={RouterLink} to="/privacy" className="login-link">Privacy Policy</Link>.
            </Typography>
          }
        />
    
      </Box>

      <RegisterButton onClick={handleSubmit}>Register</RegisterButton>

      <Box className="already-member" sx={{ mt: '59px', ml: '740px', [theme.breakpoints.down('sm')]: { width: '80%', mt: '19rem', ml: '0', fontSize: '12px' } }}>
        Already a Member? <Link component={RouterLink} to="/login" className="login-link">Login</Link>
      </Box>
    </Box>
  );
};

export default CreateNewAccount;
