import React from 'react';
import { styled } from '@mui/material/styles';

const DividerWithText = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  color: '#FFF',
  margin: '20px 0 10px',
  '&:before, &:after': {
    content: '""',
    flex: 1,
    borderBottom: '2px solid #FFF',
  },
  '&:before': {
    marginRight: '10px',
  },
  '&:after': {
    marginLeft: '10px',
  }
}));

const ORText = styled('div')({
    padding: '5px 15px',  // Padding inside the OR text box
    borderRadius: '40px',  // Rounded borders
    backgroundColor: '#E8364E',  // Background color
    color: '#FFF',  // Text color
    fontfamily:  'Poppins',
    display: 'inline-block'
  });

const ORDivider = () => (
  <DividerWithText>
   <ORText>or</ORText> 
  </DividerWithText>
);
export default ORDivider;