import React from "react";
import { useNavigate } from "react-router-dom";

const EventCard = ({ data }) => {
  const navigate = useNavigate();
  //const iconPath = process.env.PUBLIC_URL + "/icons/";

  const handleCardClick = () => {
    // navigate(`/event-details/${data.id}`);
    window.location.href = `/event-details/${data.id}`;
  };

  return (
    <div className="event-card-container" onClick={handleCardClick}>
      <div className="event-card">
        <img src={data.imageUrl} alt="Card Image" className="card-image" />
        <div className="card-details">
          <div className="date">
            <div>
              {data.date === undefined || data.date === null
                ? ""
                : data.date.toLocaleString("default", { month: "short" })}
            </div>
            <div>{data.date === undefined || data.date === null ? '' : data.date.getDate()}</div>
          </div>
          <div className="content">
            <h2 className="heading">
              {data.category} • {data.sub_category}
            </h2>
            <p className="name">{data.name}</p>
            <p className="price">Rs. {data.ticket_price}</p>
            <div className="location-section">
              <img src={"/icons/map-pin-black.svg"} alt="Location Icon" />
              <p className="location">{data.location}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
