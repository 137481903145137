import React from "react";

const BackButton = ({ data }) => {
  const iconPath = process.env.PUBLIC_URL + "/icons/";
  console.log(data.text);
  return (
    <div className="back-button" onClick={data.onClick}>
      <img src={iconPath + "back.svg"} />
      <p className="button-text">{data.text}</p>
    </div>
  );
};

export default BackButton;
