import React, { useState } from "react";
import axios from "axios";
import "../styles/SignupPage.css";
import "../styles/imagestyle.css";
import "../styles/Loader.css"; // Import the CSS file containing loader styles
import Logo from "../logo.svg";
import { useNavigate } from "react-router-dom";
import CreateNewAccount from "../components/CreateNewAccount"; // Import the component

const SignupPage = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    agreeToTerms: false,
  });

  const navigate = useNavigate();
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [nameError, setNameError] = useState({ firstName: "", lastName: "" });
  const [termsError, setTermsError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); // Loader state
  const apiUrl = process.env.REACT_APP_API_URL;

  const validateEmail = (email) => {
    if (!email) return "Email cannot be empty";
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email) ? "" : "Invalid email address";
  };

  const validatePassword = (password) => {
    if (!password) return "Password cannot be empty";
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasMinLength = password.length >= 8;

    if (!hasUpperCase) return "Password must include an uppercase letter";
    if (!hasLowerCase) return "Password must include a lowercase letter";
    if (!hasNumbers) return "Password must include a number";
    if (!hasMinLength) return "Password must be at least 8 characters long";
    return "";
  };

  const validateName = (name) => {
    if (!name) return "Name cannot be empty";
    if (name.length > 20) return "Name cannot be more than 20 characters";
    return "";
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  
    // Clear error messages on input change
    if (name === "firstName" || name === "lastName") {
      setNameError({
        ...nameError,
        [name]: "",
      });
    } else if (name === "email") {
      setEmailError("");
    } else if (name === "password") {
      setPasswordError("");
    }
  };  

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async () => {
    const emailError = validateEmail(formData.email);
    const passwordError = validatePassword(formData.password);
    const firstNameError = validateName(formData.firstName);
    const lastNameError = validateName(formData.lastName);

    setEmailError(emailError);
    setPasswordError(passwordError);
    setNameError({ firstName: firstNameError, lastName: lastNameError });
    setTermsError(formData.agreeToTerms ? "" : "You must agree to the terms");

    if (
      !emailError &&
      !passwordError &&
      !firstNameError &&
      !lastNameError &&
      formData.agreeToTerms
    ) {
      setLoading(true); // Show loader
      try {
        const response = await axios.post(`${apiUrl}/signup`, formData);
        //console.log("Signup Response:", response.data);

        if (response.data.error === "User with email already exists") {
          alert("User with this email already exists");
        } else {
          navigate("/verify", { state: { email: formData.email } });
        }
      } catch (error) {
        console.error("Error during signup:", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error === "User with email already exists"
        ) {
          alert("User with this email already exists");
        } else {
          alert("An unexpected error occurred. Please try again later.");
        }
      } finally {
        setLoading(false); // Hide loader
      }
    }
  };

  return (
    <div className="signup bg">
      {loading && (
        <div className="loader">Sending verification email! Please wait...</div>
      )}
      <div className="logo-container">
        <img src={Logo} className="signup-logo" alt="Logo" />
        <h3 className="copyright-2021-2022">MOQAH.PK</h3>
      </div>

      <CreateNewAccount
        formData={formData}
        showPassword={showPassword}
        emailError={emailError}
        passwordError={passwordError}
        nameError={nameError}
        termsError={termsError}
        handleInputChange={handleInputChange}
        togglePasswordVisibility={togglePasswordVisibility}
        handleSubmit={handleSubmit}
      />

      <div className="image-container">
        <img
          src={`${process.env.PUBLIC_URL}/image1.png`}
          alt="Decorative Element 1"
          className="circular-image image1"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image2.png`}
          alt="Decorative Element 2"
          className="circular-image image2"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image3.png`}
          alt="Decorative Element 3"
          className="circular-image image3"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image4.png`}
          alt="Decorative Element 4"
          className="circular-image image4"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image5.png`}
          alt="Decorative Element 5"
          className="circular-image image5"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image6.png`}
          alt="Decorative Element 6"
          className="circular-image image6"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image7.png`}
          alt="Decorative Element 7"
          className="circular-image image7"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image8.png`}
          alt="Decorative Element 8"
          className="circular-image image8"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image9.png`}
          alt="Decorative Element 9"
          className="circular-image image9"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image10.png`}
          alt="Decorative Element 10"
          className="circular-image image10"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image11.png`}
          alt="Decorative Element 11"
          className="circular-image image11"
        />
      </div>
    </div>
  );
};

export default SignupPage;
