import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import '../styles/VerificationPage.css';
import '../styles/VerifySuccessPage.css';

const VerifySuccessPage = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/login');
  };

  return (
    <div className="verification-spage signup bg">
      <div className="logo-container1">
        <div className="emoji-svg-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="124"
            height="123"
            viewBox="0 0 124 123"
            fill="none"
          >
            <ellipse
              cx="61.7565"
              cy="61.0274"
              rx="61.7565"
              ry="61.0274"
              fill="#2C9CF0"
            />
            <ellipse
              cx="61.7565"
              cy="61.0274"
              rx="61.7565"
              ry="61.0274"
              fill="white"
              fillOpacity="0.88"
            />
            <text
              x="50%"
              y="50%"
              textAnchor="middle"
              dominantBaseline="middle"
              fontSize="50"
              fontFamily="Arial"
              fill="#000"
            >
              😍
            </text>
          </svg>
        </div>
        <h1 className="congratulations-text">Congratulations 🎉</h1>
        <p className="verified-text">Your Email Address was successfully verified</p>
        <Button
          sx={{
            backgroundColor: 'var(--Primary, #7848F4)',
            borderRadius: '5px',
            width: { xs: '90%', sm: '80%', md: '453px' }, // Responsive width
            height: '49px',
            padding: { xs: '10px 20px', md: '15px 40px' }, // Responsive padding
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            flexShrink: 0,
            color: '#FFF',
            textAlign: 'center',
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: 'Inter',
            fontSize: { xs: '12px', md: '16px' }, // Responsive font size
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '24px', /* 150% */
            letterSpacing: '0.15px',
            
            '&:hover': {
              backgroundColor: 'var(--Primary, #7848F4)',
            },
            marginTop: '15px', // Space between the verified text and the button
            textTransform: 'none',
          }}
          onClick={handleButtonClick}
        >
          Let's Go!
        </Button>
      </div>
      <div className="verification-page signup bg">
      <div className="image-container">
        <img
          src={`${process.env.PUBLIC_URL}/image1-1.png`}
          alt="Decorative Element 11"
          className="circular-images image11"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image2-2.png`}
          alt="Decorative Element 22"
          className="circular-images image21"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image4-4.png`}
          alt="Decorative Element 44"
          className="circular-images image41"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image5-5.png`}
          alt="Decorative Element 55"
          className="circular-images image51"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image6-6.png`}
          alt="Decorative Element 66"
          className="circular-images image61"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image7-7.png`}
          alt="Decorative Element 77"
          className="circular-images image71"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image8-8.png`}
          alt="Decorative Element 88"
          className="circular-images image81"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image9-9.png`}
          alt="Decorative Element 99"
          className="circular-images image91"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image10-10.png`}
          alt="Decorative Element 101"
          className="circular-images image101"
        />
        <img
          src={`${process.env.PUBLIC_URL}/image11-11.png`}
          alt="Decorative Element 111"
          className="circular-images image111"
        />
      </div>
      </div>
    </div>
  );
};

export default VerifySuccessPage;
