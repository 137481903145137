import React, { useState } from 'react';
import '../styles/SignupPage.css';
import '../styles/LoginPage.css';
import '../styles/imagestyle.css';
import '../styles/ForgotPage.css';
import Logo from '../logo.svg';
import Typography from '@mui/material/Typography';
import EmailInput from '../components/EmailInput';

const ForgotPage = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);

  const handleEmailSent = () => {
    setIsButtonDisabled(true);
    setTimeLeft(60);

    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          setIsButtonDisabled(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  return (
    <div className="signup bg">
      <div className="logo-container">
        <img src={Logo} className="signup-logo" alt="Logo" />
        <h3 className="copyright-2021-2022">MOQAH.PK</h3>
      </div>
      <div className="head-text">
        <div className="text-container">
          <div className="style">
            Unleash the <span className="break-on-desktop"></span>Magic of Events
          </div>
          <div className="subHeading"> 
            Enter your Email details below to reset password
            <div style={{ marginTop: '25px' }}> 
              <EmailInput onEmailSent={handleEmailSent} isButtonDisabled={isButtonDisabled} />
            </div>
            <div style={{ marginTop: '15px' }}>
              <Typography
                variant="body2"
                sx={{
                  color: '#FFF',
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  textDecoration: 'underline',
                }}
              >
                Please proceed on the link that was sent to your registered email
              </Typography>
            </div>
            <div className="auth-links" style={{ marginTop: '15px' }}>
            <Typography
              variant="body2"
              component="span"
              className="forgot-password"
              sx={{
                color: '#E8364E', // Replace with your desired color
              }}
            >
              {timeLeft > 0 ? `Didn't get an email? Resend again in ${timeLeft}s` : null}
            </Typography>

            </div>
          </div>
        </div>
      </div>
      <div className="image-container">
        <div className="image-container">
          <img src={`${process.env.PUBLIC_URL}/image1.png`} alt="Decorative Element 1" className="circular-image image1" />
          <img src={`${process.env.PUBLIC_URL}/image2.png`} alt="Decorative Element 2" className="circular-image image2" />
          <img src={`${process.env.PUBLIC_URL}/image3.png`} alt="Decorative Element 3" className="circular-image image3" />
          <img src={`${process.env.PUBLIC_URL}/image4.png`} alt="Decorative Element 4" className="circular-image image4" />
          <img src={`${process.env.PUBLIC_URL}/image5.png`} alt="Decorative Element 5" className="circular-image image5" />
          <img src={`${process.env.PUBLIC_URL}/image6.png`} alt="Decorative Element 6" className="circular-image image6" />
          <img src={`${process.env.PUBLIC_URL}/image7.png`} alt="Decorative Element 7" className="circular-image image7" />
          <img src={`${process.env.PUBLIC_URL}/image8.png`} alt="Decorative Element 8" className="circular-image image8" />
          <img src={`${process.env.PUBLIC_URL}/image9.png`} alt="Decorative Element 9" className="circular-image image9" />
          <img src={`${process.env.PUBLIC_URL}/image10.png`} alt="Decorative Element 10" className="circular-image image10" />
          <img src={`${process.env.PUBLIC_URL}/image11.png`} alt="Decorative Element 11" className="circular-image image11" />
        </div>
      </div>
    </div>
  );
}

export default ForgotPage;
