import React from 'react';
import Button from '@mui/material/Button';

const VerifyButton = () => {
  return (
    <Button
      sx={{
        // Set up basic responsive layout properties
        position: 'absolute', // Change to relative positioning for better flexbox alignment
        margin: '0 auto', // Center horizontally when using flexbox
        display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center',
        top: {
          xs: '20%',
          sm: '20%',
          md: '18%',
          lg: '55%', // Further reduced width on extra large screens
          xl: '55%',
        },
        left: {
          xs: '4%',
          sm: '10%',
          md: '10%',
          lg: '18%', // Further reduced width on extra large screens
          xl: '18%',
        },

        // Responsive sizing
        width: {
          xs: '90%', // Full width on mobile devices
          sm: '80%',
          md: '80%', // Reduced width on larger screens
          lg: '70%', // Further reduced width on extra large screens
          xl: '70%', // Even smaller on the largest screens
        },
        height: {
          xs: '60px',
          sm: '80px',
          md: '80px',
          lg: '120px', // Taller on extra large screens
        },

        // Styling
        backgroundColor: 'rgba(120, 72, 244, 0.72)',
        boxShadow: '0px 0px 20px rgba(76, 78, 100, 0.40)',
        color: '#FFF',
        fontFamily: 'Poppins, sans-serif',
        fontSize: {
          xs: '16px',
          sm: '20px',
          md: '24px', // Larger font size on medium screens
          lg: '28px', // Even larger on large screens
          xl: '32px', // Largest on extra large screens
        },
        fontWeight: 700,
        lineHeight: 'normal',
        textTransform: 'none',
        borderRadius: '50px',
        marginTop: '-10px', // Optional: adjust for visual balance
        '&:hover': {
          backgroundColor: 'rgba(120, 72, 244, 0.72)',
        },

        // Additional spacing for larger screens
        padding: {
          xs: '10px 20px',
          sm: '15px 30px',
          md: '20px 40px',
          lg: '25px 50px', // Increase padding on large screens
        },
      }}
    >
      <span
        style={{
          position: 'absolute',
          top: '50%',
          left: '30px',
          transform: 'translateY(-50%)', // Center vertically
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          backgroundColor: '#FFF',
        }}
      />
      Verify your Email Now!!
    </Button>
  );
};

export default VerifyButton;
