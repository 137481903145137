import React, { useState } from "react";
import axios from "axios";
import { Box, TextField, Typography, IconButton, Button } from "@mui/material";
import { Clear as ClearIcon } from "@mui/icons-material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../components/Header";
import "../styles/EventPage.css"; // Include the CSS styles

const EventPage = () => {
  const [formData, setFormData] = useState({
    email: "",
    organizationName: "",
    phoneNumber: "",
    eventTitle: "",
    eventVenue: "",
    Time: null,
    Date: null,
    eventDescription: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phoneRegex =
    /^\+?[0-9]{1,4}?[-.\s]?(\([0-9]{1,3}\)|[0-9]{1,4})?[-.\s]?[0-9]{1,4}[-.\s]?[0-9]{1,9}$/;

  const apiUrl = process.env.REACT_APP_API_URL;

  const validateForm = () => {
    const newErrors = {};

    if (!emailRegex.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
    }
    if (!phoneRegex.test(formData.phoneNumber)) {
      newErrors.phoneNumber = "Please enter a valid phone number";
    }
    if (!formData.eventTitle.trim()) {
      newErrors.eventTitle = "Event Title is required";
    }
    if (!formData.eventVenue.trim()) {
      newErrors.eventVenue = "Event Venue is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const response = await axios.post(apiUrl, formData);
      //console.log(response.data);
      setIsSubmitted(true);
      setFormData({
        email: "",
        organizationName: "",
        phoneNumber: "",
        eventTitle: "",
        eventVenue: "",
        Time: null,
        Date: null,
        eventDescription: "",
      });
    } catch (error) {
      console.error("Error submitting form data", error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      Date: date,
    }));
  };

  const handleTimeChange = (time) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      Time: time,
    }));
  };

  const clearField = (field) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: field === "Time" || field === "Date" ? null : "",
    }));
  };

  return (
    <>
      <Header searchBar={false} />
      <Box className="container" component="form" onSubmit={handleSubmit}>
        <Typography variant="h4" gutterBottom align="center">
          Event Registration
        </Typography>

        <Box className="text-field">
          <Typography className="subtitle" variant="subtitle1" gutterBottom>
            Email
          </Typography>
          <TextField
            name="email"
            placeholder="Enter your email"
            variant="outlined"
            value={formData.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
            fullWidth
            InputProps={{
              endAdornment: formData.email && (
                <IconButton onClick={() => clearField("email")}>
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
        </Box>

        <Box className="text-field">
          <Typography className="subtitle" variant="subtitle1" gutterBottom>
            Organization Name
          </Typography>
          <TextField
            name="organizationName"
            placeholder="Enter organization name"
            variant="outlined"
            value={formData.organizationName}
            onChange={handleChange}
            fullWidth
            InputProps={{
              endAdornment: formData.organizationName && (
                <IconButton onClick={() => clearField("organizationName")}>
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
        </Box>

        <Box className="text-field">
          <Typography className="subtitle" variant="subtitle1" gutterBottom>
            Phone Number
          </Typography>
          <TextField
            name="phoneNumber"
            placeholder="Enter contact number"
            variant="outlined"
            value={formData.phoneNumber}
            onChange={handleChange}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber}
            fullWidth
            InputProps={{
              endAdornment: formData.phoneNumber && (
                <IconButton onClick={() => clearField("phoneNumber")}>
                  <ClearIcon />
                </IconButton>
              ),
            }}
            onKeyDown={(e) => {
              const key = e.key;
              // Allow only numbers, '+', and control keys like backspace, delete, and arrow keys
              if (
                !/[0-9+]/.test(key) &&
                key !== "Backspace" &&
                key !== "Delete" &&
                key !== "ArrowLeft" &&
                key !== "ArrowRight" &&
                key !== "Tab"
              ) {
                e.preventDefault();
              }
            }}
          />
        </Box>

        <Box className="text-field">
          <Typography className="subtitle" variant="subtitle1" gutterBottom>
            Event Title
          </Typography>
          <TextField
            name="eventTitle"
            placeholder="Enter event title"
            variant="outlined"
            value={formData.eventTitle}
            onChange={handleChange}
            error={!!errors.eventTitle}
            helperText={errors.eventTitle}
            fullWidth
            InputProps={{
              endAdornment: formData.eventTitle && (
                <IconButton onClick={() => clearField("eventTitle")}>
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
        </Box>

        <Box className="text-field">
          <Typography className="subtitle" variant="subtitle1" gutterBottom>
            Event Venue
          </Typography>
          <TextField
            name="eventVenue"
            placeholder="Enter event venue"
            variant="outlined"
            value={formData.eventVenue}
            onChange={handleChange}
            error={!!errors.eventVenue}
            helperText={errors.eventVenue}
            fullWidth
            InputProps={{
              endAdornment: formData.eventVenue && (
                <IconButton onClick={() => clearField("eventVenue")}>
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
        </Box>

        <Box className="text-field">
          <Typography className="subtitle" variant="subtitle1" gutterBottom>
            Time
          </Typography>
          <DatePicker
            selected={formData.Time}
            onChange={handleTimeChange}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
            placeholderText="10:00 AM"
            customInput={
              <TextField
                variant="outlined"
                fullWidth
                error={!!errors.Time}
                helperText={errors.Time}
                InputProps={{
                  endAdornment: formData.Time && (
                    <IconButton onClick={() => clearField("Time")}>
                      <ClearIcon />
                    </IconButton>
                  ),
                  onKeyDown: (e) => {
                    const allowedKeys = [
                      "0",
                      "1",
                      "2",
                      "3",
                      "4",
                      "5",
                      "6",
                      "7",
                      "8",
                      "9", // Numbers
                      ":",
                      " ", // Colon
                      "a",
                      "p",
                      "A",
                      "P",
                      "m",
                      "M", // AM/PM
                      "Backspace",
                      "ArrowLeft",
                      "ArrowRight",
                      "Delete",
                      "Tab", // Navigation & editing keys
                    ];

                    if (!allowedKeys.includes(e.key)) {
                      e.preventDefault();
                    }
                  },
                }}
              />
            }
          />
        </Box>

        <Box className="text-field">
          <Typography className="subtitle" variant="subtitle1" gutterBottom>
            Date
          </Typography>
          <DatePicker
            selected={formData.Date}
            onChange={handleDateChange}
            dateFormat="yyyy-MM-dd"
            placeholderText="2024-10-13"
            customInput={
              <TextField
                variant="outlined"
                fullWidth
                error={!!errors.Date}
                helperText={errors.Date}
                InputProps={{
                  endAdornment: formData.Date && (
                    <IconButton onClick={() => clearField("Date")}>
                      <ClearIcon />
                    </IconButton>
                  ),
                  onKeyDown: (e) => {
                    const allowedKeys = [
                      "0",
                      "1",
                      "2",
                      "3",
                      "4",
                      "5",
                      "6",
                      "7",
                      "8",
                      "9", // Numbers
                      "-", // Hyphen
                      "Backspace",
                      "ArrowLeft",
                      "ArrowRight",
                      "Delete",
                      "Tab", // Navigation & editing keys
                    ];

                    if (!allowedKeys.includes(e.key)) {
                      e.preventDefault();
                    }
                  },
                }}
              />
            }
          />
        </Box>

        <Box className="text-field">
          <Typography className="subtitle" variant="subtitle1" gutterBottom>
            Event Description
          </Typography>
          <TextField
            name="eventDescription"
            placeholder="Enter event description"
            variant="outlined"
            multiline
            rows={4}
            value={formData.eventDescription}
            onChange={handleChange}
            fullWidth
            InputProps={{
              endAdornment: formData.eventDescription && (
                <IconButton onClick={() => clearField("eventDescription")}>
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
        </Box>

        <Box className="button-container">
          <Button
            className="submit-button"
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit Your Information
          </Button>
        </Box>
        {/* <br></br> */}
        {isSubmitted && (
          <Typography
            className="success-message"
            style={{ fontWeight: "bold", fontSize: "12px", marginTop: "10px" }}
          >
            Thanks for submitting. Our team will contact you soon.
          </Typography>
        )}
      </Box>
    </>
  );
};

export default EventPage;
