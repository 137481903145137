import React, { useState, useEffect } from "react";

const Banner = ({ heading, subheading, data }) => {
  const [slide, setSlide] = useState(0);

  const nextSlide = () => {
    setSlide(slide === data.length - 1 ? 0 : slide + 1);
  };

  const prevSlide = () => {
    setSlide(slide === 0 ? data.length - 1 : slide - 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000); // Change the duration according to your preference (3 seconds = 3000 milliseconds)
    return () => clearInterval(interval);
  }, [slide]);

  return (
    <div className="banner-container">
      <div className="heading-container">
        <div className="heading">
          <h6>{heading}</h6>
        </div>
        <div className="subheading">
          <h6>{subheading}</h6>
        </div>
      </div>
      <div className="carousel-container">
        <div className="carousel">
          {/* <img onClick={prevSlide} className="arrow arrow-left" /> */}
          {data.map((item, idx) => {
            return (
              <div
                key={idx}
                className={slide === idx ? "slide" : "slide slide-hidden"}
              >
                <img src={item.src} alt={item.alt} className="carousel-image" />
                {/* <div className="carousel-text">{item.alt}</div> */}
              </div>
            );
          })}
          {/* <img onClick={nextSlide} className="arrow arrow-right" /> */}
          <span className="indicators">
            {data.map((_, idx) => {
              return (
                <button
                  key={idx}
                  className={
                    slide === idx ? "indicator" : "indicator indicator-inactive"
                  }
                  onClick={() => setSlide(idx)}
                ></button>
              );
            })}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Banner;
