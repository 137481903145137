import React from "react";
import CitiesCard from "./CitiesCard";

const CityCards = ({ data }) => {
  return (
    <div className="city-cards-wrapper">
      <div className="city-cards">
        {data.map((city) => (
          <CitiesCard
            key={city.name}
            heading={city.name}
            link={city.dest}
            imageUrl={city.imgUrl}
            active={city.active}
          />
        ))}
      </div>
    </div>
  );
};

export default CityCards;
