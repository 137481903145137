import React from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import Logo from "../logo.svg";

const Footer = () => {
  const iconsPath = process.env.PUBLIC_URL + "/icons/";
  
  const data = [
    { 
      label: "Email", 
      value: "moqah.pk@gmail.com", 
      icon: "email.svg", 
      onClick: () => window.open("mailto:moqah.pk@gmail.com", "_blank") 
    },
    { 
      label: "Phone Number", 
      value: "0335 5880869", 
      icon: "phone.svg", 
      onClick: () => window.open("tel:03355880869", "_blank") 
    },
    { 
      label: "Whatsapp", 
      value: "0335 5880869", 
      icon: "wa.svg", 
      onClick: () => window.open("https://api.whatsapp.com/send?phone=03355880869", "_blank") 
    },
    { 
      label: "Instagram", 
      value: "moqah.pk", 
      icon: "inst.svg", 
      onClick: () => window.open("https://www.instagram.com/moqah.pk", "_blank") 
    },
    { 
      label: "Facebook", 
      value: "moqah.pk", 
      icon: "fb.svg", 
      onClick: () => window.open("https://www.facebook.com/moqah.pk", "_blank") 
    },
  ];

  return (
    <footer>
      <div className="footer">
        <div>
          <a href="../home">
            <div className="logo">
              <img src={Logo} alt="MOQAH.Pk Logo" />
              <h6>MOQAH.Pk</h6>
            </div>
          </a>
        </div>
        <div className="all-links">
          <div className="links">
            {/* Add any additional links or content here */}
          </div>
        </div>
        <div className="information">
          <table>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} onClick={item.onClick} style={{ cursor: item.onClick ? "pointer" : "default" }}>
                  <td className="table-icons">
                    <img src={iconsPath + item.icon} alt={`${item.label} Icon`} />
                  </td>
                  <td className="table-headings">{item.label}</td>
                  <td>{item.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="copyright">
        <p>
          &copy; {new Date().getFullYear()} <span>TECHUBBLE</span> Private Limited
        </p>
      </div>
    </footer>
  );
};

export default Footer;
