import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const DarkButton = ({ dest, content, className, comingSoonMessage }) => {
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      setPointerPosition({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const handleMouseEnter = () => {
    setShowComingSoon(true);
  };

  const handleMouseLeave = () => {
    setShowComingSoon(false);
  };

  return (
    <div
      className={`dark-button inline-flex ${className}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Link to={dest} offset={-80} duration={500}>
        <h6>{content}</h6>
      </Link>
      {showComingSoon && className.includes("grey") && (
        <span
          className="coming-soon"
          style={{
            top: pointerPosition.y + 15,
            left: pointerPosition.x + 15,
            width:'110px',
            height:'40px',
          }}
        >
          Coming soon
        </span>
      )}
    </div>
  );
};

export default DarkButton;
